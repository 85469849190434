<template>
  <div class="login-page">
    <div class="page-header">
      <div class="logo">
        <img :src="require('@/assets/logo/logo.png')" />
        <div class="title">
          <span style="font-size: 24px; color: #ffffff">电器维修案件管理系统</span>
        </div>
      </div>
    </div>
    <div class="page-body">
      <div class="body-mask"></div>
      <div class="page-login">
        <div class="login-info">

        </div>
        <div class="login-box">
          <div class="login-form">
            <div class="login-title">账号登录</div>
            <el-form ref="form" :model="form" :rules="rules" auto-complete="on" label-position="left" >
              <el-row :gutter="10" style="padding: 8px 0px">
                <el-col :span="24">
                  <el-form-item prop="username">
                    <el-input
                      ref="name"
                      v-model="form.username"
                      placeholder="用户名"
                      type="text"
                      tabindex="1"
                      auto-complete="on"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="10" style="padding: 8px 0px">
                <el-col :span="24">
                  <el-form-item prop="password">
                    <el-input
                      ref="password"
                      v-model="form.password"
                      :type="passwordType"
                      placeholder="密码"
                      tabindex="2"
                      auto-complete="on"
                      @keyup.enter.native="handleLogin"
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- <el-row :gutter="10" style="padding: 8px 0px">
                <el-col :span="24">
                  <el-form-item prop="password">
                    <el-input
                      ref="password"
                      type="text"
                      placeholder="验证码"
                      tabindex="2"
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row> -->
              <el-row :gutter="10" style="padding: 8px 0px">
                <el-col :span="24">
                  <el-button
                    :loading="loading"
                    type="primary"
                    style="width: 100%;background-color: #0a9a77;"
                    @click.native.prevent="handleLogin"
                    >登 录</el-button
                  >
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <div class="page-footer">
      <div class="copyright">
        <span style="font-size: 14px; color: #ababab"
          >Copyright © 2021-2022 版权所有 侵权必究</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          { required: true, trigger: "blur", message: "请输入用户名" },
        ],
        password: [
          { required: true, trigger: "blur", message: "请输入密码" },
          { min: 6, max: 18, trigger: "blur", message: "密码长度为6到18位" },
        ],
      },
      loading: false,
      passwordType: "password",
    };
  },
  methods: {
    showPassword() {
      this.passwordType = this.passwordType === "password" ? "" : "password";
      this.$nextTick(() => {
        this.$refs.password.focus();
      });
    },
    handleLogin() {
      const that = this;
      that.$refs.form.validate((valid) => {
        if (valid) {
          that.loading = true;
          that.request
            .post("sysuser/login", that.form)
            .then((res) => {
              // 此处只接收成功数据，失败数据不返回
              console.log(res);
              console.log(that.$router);
              if (res.code === 200) {
                sessionStorage.setItem("token", res.data.token);
                that.$store.commit("setMenu", res.data.menuList);
                let userinfo = res.data;
                userinfo.menuList = [];
                userinfo.token = "";
                that.$store.commit("setUser", userinfo);
                that.$store.dispatch("initRoutes");
                console.log(that.$router);
                that.loading = false;
                that.$router.push("/setting/index");
              }
            })
            .catch(() => {
              // catch 可以不要，如果想要捕获异常，就加上去
              that.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.login-page {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #fff;
  .page-header {
    width: 100%;
    height: 80px;
    background-color: #0a9a77;
    .logo {
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 100px;
      img {
        height: 110%;
      }
    }
    .title {
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      align-items: baseline;
      justify-content: space-between;
    }
  }
  .page-body {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url("../assets/images/login-bg1.png");
    background-size: cover;
    background-position: bottom center;
    .body-mask {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #f2ffffad;
    }
    .page-login{
      position: absolute;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      width: 100%;
      height: 100%;
      .login-info{
        width: 720px;
        height: 400px;
        margin-top: -140px;
      }
      .login-box {
        width: 360px;
        height: auto;
        margin-top: -140px;
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0 2px 12px 0 #ece9e9;
        .login-form {
          padding: 28px 42px 30px;
          .login-title {
            font-size: 20px;
            font-weight: bolder;
            color: #262626;
            text-align: center;
            margin-bottom: 26px;
          }
        }
      }
    }
  }
  .page-footer {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 60px;
    line-height: 60px;
    background-color: #f5f5f5c2;
    .copyright {
      text-align: center;
    }
  }
}
</style>
